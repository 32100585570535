<template>
    <div class="fufei">
        <div class="fufei_img">
            <img src="@/assets/image/icon121.png" alt="">
        </div>
        <div class="contain">
            <div class="contain_title">付费资讯</div>
            <div class="contain_line"></div>
            <div class="list">
                <div class="list_item" v-for="(item, index) in list" :key="index" @click="show = true">
                    <div>{{item.name1}}{{item.name2}}</div>
                    <div></div>
                    <div>{{item.time}}</div>
                    <div class="contain_line"></div>

                </div>
            </div>
        </div>
        <el-dialog title="支付" :visible.sync="show" width="30%">
      <div class="wxpay" style="display: flex;justify-content: center">
        <img src="@/assets/image/wxpay.png" alt="" style="width: 400px;" />
      </div>
    </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            list:[
                {name1:'从0开始，怎么赚大钱!!!',name2:'【付费5元阅读全文】',name3:'本文章教您如果赚大钱，一对一辅导教育',time:'2024.05.01 09:13:15'},
                {name1:'电商快跑起来~',name2:'【付费5元阅读全文】',name3:'本文章教您如果赚大钱，一对一辅导教育',time:'2024.05.02 10:14:14'},
                {name1:'【付费5元阅读全文】',name2:'均衡思维，更稳更快入门!',name3:'本文章教您如果赚大钱，一对一辅导教育',time:'2024.05.03 11:15:13'},
                {name1:'如何打造爆款产品、创业环境~',name2:'【付费5元阅读全文】',name3:'本文章教您如果赚大钱，一对一辅导教育',time:'2024.05.04 12:16:12'},
                {name1:'【付费5元阅读全文信用消费】，',name2:'选银行还是选电商。',name3:'文章教您如果赚大钱，一对一辅导教育',time:'2024.05.05 15:17:11'},
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.fufei {
    min-height: 100vh;
}

.fufei_img {
    width: 100%;

    img {
        width: 100%;
        height: 300px;
    }
}

.contain {
    width: 1400px;
    margin: 24px auto;
    background: #fff;
    padding: 24px;
    box-sizing: border-box;
    border-radius: 12px;

    .contain_title {
        font-size: 22px;
        font-weight: 500;
    }

    .contain_line {
        width: 100%;
        height: 1px;
        background: #e9e9e9;
        margin: 24px 0;
    }

    .list_item {
        cursor: pointer;

        div:nth-child(1) {
            font-size: 18px;
            color: #333;
            font-weight: 700;

        }

        div:nth-child(1):hover {
            font-size: 18px;
            color: #2870ff;
        }

        div:nth-child(2) {
            font-size: 16px;
            margin: 12px 0;

        }

        div:nth-child(3) {
            font-size: 14px;
            color: #c4c4c4;


        }
    }
}
</style>